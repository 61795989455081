import React from 'react';
import Typography from '@material-ui/core/Typography';

import { makeStyles, useTheme } from '@material-ui/styles'; 
import useMediaQuery from '@material-ui/core/useMediaQuery'; 

import InfoOutlined from '@material-ui/icons/InfoOutlined';
import TextsmsIcon from '@material-ui/icons/Textsms';
import EventIcon from '@material-ui/icons/Event';
import ButtonLink from 'components/ButtonLink'; 

export default function RequestConsulationButton({destination}) {
	const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.up('xs'));
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));  
  return ( 
    <ButtonLink style={{ 
        fontSize: 14,
        width: !matchesMd ? '100%' : 360,
        letterSpacing: 1,
        padding: '8px 24px', 
        textTransform: 'capitalize', 
      }} variant="contained" href={destination ? `/get-started/availability?destination=${destination}&packageName=Consultation` 
        : "/get-started/availability?packageName=Consultation"} 
      color="secondary"><TextsmsIcon/>&nbsp;&nbsp;&nbsp;Request a Free Consultation</ButtonLink> 
  );
}
