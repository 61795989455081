import React from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar'; 
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';  
import Button from '@material-ui/core/Button'; 
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia'; 
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box'; 

import Link from 'components/Link'; 
import CardActionAreaLink from 'components/CardActionAreaLink';

import LazyLoad from 'react-lazyload';



const cardListItemMedia = {
  'default': {
    height: 142,
    paddingTop: '51.43%',  
  },
  'wedwedgo.com': {
    height: 300,
    paddingTop: '51.43%', 
    filter: 'sepia(0.4)'
  }
}

const mediaContentTitle = {
  'default': {
    font: '700 20px/24px Roboto, Arial, sans-serif',
    margin: 0
  },
  'wedwedgo.com': {
    font: '700 20px/24px Roboto, Arial, sans-serif',
    margin: 0,
    textAlign: 'center'
  }
}

const mediaContentText = {
  'default': {
    color: 'white',
    font: '700 12px/16px Roboto, Arial, sans-serif',
    letterSpacing: '0.3px',
    marginTop: 4
  },
  'wedwedgo.com': {
    color: 'white',
    font: '700 12px/16px Roboto, Arial, sans-serif',
    letterSpacing: '0.3px',
    marginTop: 4,
    textAlign: 'center'
  }
}

const useStyles = makeStyles((theme) => ({
  rootXs: {
    flexGrow: 1,
    flexBasis: 'calc(100%)',
    margin: '8px 0',
    borderRadius: 8
  },
  rootSm: { 
    flexBasis: 'calc(50% - 16px)',
    margin: '8px',
    borderRadius: 8
  },
  rootMd: { 
    flexBasis: 'calc(33.333% - 16px)',
    margin: '8px',
    borderRadius: 8
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontSize: '25px'
  },
  cardListItemMedia: cardListItemMedia[process.env["NEXT_PUBLIC_HOSTNAME"]] 
    ? cardListItemMedia[process.env["NEXT_PUBLIC_HOSTNAME"]] 
    : cardListItemMedia['default'], 
  mediaContent: {
    position: 'absolute',
    bottom: 0,
    color: 'white',
    font: '400 20px/24px Roboto, Arial, sans-serif',
    background: 'linear-gradient(to top, black, rgba(0, 0, 0, 0))',
    width: '100%',
  },
  mediaContentTitle: mediaContentTitle[process.env["NEXT_PUBLIC_HOSTNAME"]] 
    ? mediaContentTitle[process.env["NEXT_PUBLIC_HOSTNAME"]] 
    : mediaContentTitle['default'],
  mediaContentText: mediaContentText[process.env["NEXT_PUBLIC_HOSTNAME"]] 
    ? mediaContentText[process.env["NEXT_PUBLIC_HOSTNAME"]] 
    : mediaContentText['default'],
})); 

// background: rgba(0,0,0,.12);
export default function CardListItem({item}) {
  const classes = useStyles();  
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.up('xs'));
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.up('md')); 

  // console.log('theme ', theme)

  // console.log('item.src ', item.src)
  
  return (  
    <Card className={(matchesMd ? classes.rootMd : matchesSm ? classes.rootSm : classes.rootXs)  + ' allowRightClick'}>   
      <CardActionAreaLink href={'/' + item.slug}>
        <LazyLoad height={200} offset={1200}>
        <CardMedia
          className={classes.cardListItemMedia}
          // image={item.src}
          image={item.src || '/img/icons/icon-no-image.svg'}
          title={item.name}
        />
         </LazyLoad>
        <CardContent className={classes.mediaContent}>
          <Box
            sx={{ 
              textAlign: 'left'
            }}> 
            <Typography className={classes.mediaContentTitle} gutterBottom variant="h5" component="div">
              {item.name}
            </Typography>
            {item.shortDesc && item.shortDesc !== '' ? <Typography className={classes.mediaContentText} variant="body2" color="textSecondary" component="p">
              {item.shortDesc}
            </Typography> : null }
          </Box> 
        </CardContent>
      </CardActionAreaLink> 
    </Card> 
  );
}
