import React from 'react';
import Typography from '@material-ui/core/Typography';

import { makeStyles, useTheme } from '@material-ui/styles'; 
import useMediaQuery from '@material-ui/core/useMediaQuery'; 

import InfoOutlined from '@material-ui/icons/InfoOutlined';
import TextsmsIcon from '@material-ui/icons/Textsms';
import EventIcon from '@material-ui/icons/Event';
import ButtonLink from 'components/ButtonLink'; 
import RequestConsultationButton from 'widgets/RequestConsultationButton'; 

export default function VenueActions({destination = false, hideIcon = false}) {
	const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.up('xs'));
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));
  const matchesMd = useMediaQuery(theme.breakpoints.up('md'));

  let today = new Date(); 

  return (<> 
    <div style={{textAlign: 'center'}}>
      <Typography style={{margin: 0, textAlign: 'center', display: 'flex'}} gutterBottom component="h3">
        <InfoOutlined style={{display: hideIcon === true ? 'none' : null}} /><span style={{color: '#25b66e', fontWeight: 'bold'}}>&nbsp; We have multiple venues/sites available for the {today.getFullYear()} - {today.getFullYear()+1} wedding season. Check Availability for a Specific Date, or Request a Free Consultation and start planning today, with no obligation</span> 
      </Typography> <br/><br/> 
      <ButtonLink style={{ 
        fontSize: 14,
        width: !matchesMd ? '100%' : 360,
        letterSpacing: 1,
        padding: '8px 24px', 
        textTransform: 'capitalize'
      }} variant="contained" href={destination ? `/get-started/availability?destination=${destination}&packageName=CheckAvailability` 
        : "/get-started/availability?packageName=CheckAvailability"} 
      color="primary"> <EventIcon/>&nbsp;&nbsp;&nbsp;Check Availability for a{matchesMd ? ' Specific' : null} Date</ButtonLink>
      <br/><br/>
      <RequestConsultationButton destination={destination} />
      <br/><br/>

      </div>
  </>);
}
