import React from 'react';
import { makeStyles, useTheme } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import MuiLink from '@material-ui/core/Link';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar'; 
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';  
import Button from '@material-ui/core/Button'; 
import CardListItem from 'components/CardListItem'; 
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    fontSize: '25px'
  },
  cardListWrapXs: {
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: '0',
    marginRight: '0'
  },
  cardListWrapSm: { 
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: '-8px',
    marginRight: '-8px'
  }
})); 

export default function CardList({items}) { 
  // console.log('items ', items)
  // const places = []; 
  // const { query } = useRouter();
  // const { items } = query;

  // console.log('items ', items)
  // const places = []; 
  // const { query } = useRouter();
  // const { items } = query;

  const theme = useTheme(); 
  const matchesSm = useMediaQuery(theme.breakpoints.up('sm'));  

  const classes = useStyles();
  return (
    <div className={matchesSm ? classes.cardListWrapSm : classes.cardListWrapXs}>
      {(items.length > 0) ? items.map((item, index) => {return <CardListItem key={index} item={item} />}) : null}
    </div>
  );
  
}
